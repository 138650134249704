import React from 'react';
import Showdown from 'showdown';
import Sanitizer from 'sanitize-html';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import SEO from '../components/atoms/SEO';
import slugify from 'slugify';
const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.section`
  width: 100%;
  max-width: 126rem;
  padding: 4rem 3rem;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    padding-right: 30rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding-right: 20rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-right: 10rem;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    padding-right: 0rem;
  }
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 60rem;
  max-height: 35rem;
`;

const StyledPostWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 950px) {
    flex-direction: row;
  }
`;

const StyledPostTextWrapper = styled.article`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
  max-width: 60rem;

  @media only screen and (min-width: 950px) {
    width: 50%;
    padding-left: 4rem;
  }
`;

const StyledContentWrapper = styled.article`
  color: ${({ theme }) => theme.font.color.main};

  p {
    line-height: 2.25rem;
    font-size: 1.5rem;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      line-height: 2.7rem;
      font-size: 1.8rem;
    }

    strong {
      margin: 3rem 0;
    }
    em {
      font-size: 2.3rem;
      color: ${({ theme }) => theme.font.main};
      position: relative;
      margin-bottom: 2rem;
      font-style: normal;

      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        font-size: 2.8rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        font-size: 3.2rem;
      }
    }
    a {
      color: ${({ theme }) => theme.font.color.accent};
      font-weight: bold;
    }
  }

  blockquote {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    p {
      font-weight: bold;
      color: ${({ theme }) => theme.font.color.main};
    }

    em {
      font-family: ${({ theme }) => theme.font.family.cursive};
      font-size: 3rem;
    }
  }

  ul {
    padding-left: 2.5rem;
    margin: 2rem 0;

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
      padding-left: 3rem;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.l}) {
      padding-left: 3.5rem;
    }

    li {
      line-height: 2.25rem;
      font-size: 1.5rem;
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.m}) {
        line-height: 2.4rem;
        font-size: 1.6rem;
      }
      @media only screen and (min-width: ${({ theme }) =>
          theme.breakpoints.l}) {
        line-height: 2.7rem;
        font-size: 1.8rem;
      }
      em {
        font-style: normal;
        font-weight: bold;
        color: ${({ theme }) => theme.font.color.main};
        border-bottom: 2px solid ${({ theme }) => theme.font.color.main};
      }
    }
  }
`;

const data = graphql`
  query BlogQueryMain {
    allDatoCmsBlog {
      nodes {
        content
        heading
        thumbnail {
          url
        }
      }
    }
  }
`;

interface Post {
  content: string;
  heading: string;
  thumbnail: {
    url: string;
  };
}

interface Props {
  data: {
    datoCmsBlog: {
      nodes: Post[];
    };
  };
}

const Blog: React.FC<Props> = () => {
  const dataBlog = useStaticQuery(data);
  const nodes = dataBlog.allDatoCmsBlog.nodes;
  return (
    <>
      <StyledContainer>
        <StyledWrapper>
          {nodes.map((blogPost: Post) => {
            const {
              content,
              heading,
              thumbnail: { url },
            } = blogPost;
            const description: string = `${content.substring(0, 250)}[...]`;
            const html = new Showdown.Converter().makeHtml(description);
            const sanitizedHTML = Sanitizer(html);
            const slugifiedHeading = slugify(heading, {
              lower: true,
            });

            return (
              <>
                <SEO title={heading} description={description} />
                <StyledPostWrapper key={slugifiedHeading}>
                  <Link to={`/blog/${slugifiedHeading}`}>
                    <StyledImageWrapper>
                      <StyledImage src={url} />
                    </StyledImageWrapper>
                  </Link>
                  <StyledPostTextWrapper>
                    <StyledContentWrapper
                      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    ></StyledContentWrapper>
                  </StyledPostTextWrapper>
                </StyledPostWrapper>
              </>
            );
          })}
        </StyledWrapper>
      </StyledContainer>
    </>
  );
};

export default Blog;
